import {
  AssuredWorkloadOutlined,
  Balance,
  Calculate,
  CloudSyncOutlined,
  Dashboard,
  DisplaySettings,
  ExitToAppOutlined,
  FileUpload,
  GroupAddOutlined,
  Groups,
  ImportantDevices,
  Insights,
  LocalPoliceOutlined,
  Menu as MenuIcon,
  MultipleStopOutlined,
  Notifications,
  PeopleAltOutlined,
  Policy,
  PollOutlined,
  PriceChangeOutlined,
  RocketLaunch,
  SafetyCheck,
  ScreenShareOutlined,
  Search,
  Settings,
  SettingsAccessibility,
  SettingsOutlined,
  StopScreenShareOutlined,
  TravelExploreOutlined,
  WaterfallChart,
} from "@mui/icons-material";
import {
  ClickAwayListener,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiMenu from "@mui/material/Menu";
import { type User, UserLocale } from "@prisma/client";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { useBoolean, useToggle } from "react-use";
import { useCommandPalette } from "~/components/command-palette/CommandPaletteContext";
import { DevUserRoleSelector } from "~/components/layout/DevUserRoleSelector";
import { ToggleSuperAdmin } from "~/components/layout/ToggleSuperAdmin";
import { ImpersonatePanel } from "~/components/modals/ImpersonatePanel";
import { RequestExceptionalImpersonationPanel } from "~/components/modals/RequestExceptionalImpersonationPanel";
import { RequestTemporarySuperAdminRightsPanel } from "~/components/modals/RequestTemporarySuperAdminRightsPanel";
import { ActiveLink } from "~/components/ui/ActiveLink";
import { Icon } from "~/components/ui/core/Icon";
import { CompensationReview } from "~/components/ui/core/icons/CompensationReviewIcon";
import { Image } from "~/components/ui/core/Image";
import { Link, type LinkProps, NextLinkComposed, type NextLinkComposedProps } from "~/components/ui/core/Link";
import { StyledPopper } from "~/components/ui/core/OptionsFilter";
import { config } from "~/config";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { compact } from "~/lib/lodash";
import { buildFiguresUrl } from "~/lib/url";
import { getFiguresCompanyId } from "~/lib/utils";
import { useStopCompanyImpersonationMutation } from "~/pages/api/auth/company-impersonation/stop-company-impersonation";
import { useUpdateUserLocaleQuery } from "~/pages/api/user/update-user-locale";

export const Navbar: React.FC = () => {
  const { t } = useI18n();
  const [showImpersonatePanel, toggleImpersonatePanel] = useBoolean(false);
  const { isLoadingUser, user, impersonatingCompany, signOut } = useSession();

  const { CAN_ACCESS_COMPENSATION_PLANNING } = useFeatureFlags();
  const { showPalette, togglePalette } = useCommandPalette();

  const { permissions } = usePermissions();

  const isVentureCapital = user?.company.type === "VENTURE_CAPITAL";

  const signOutAndRedirect = async () => {
    await signOut();
    window.location.replace(config.app.isProduction ? config.app.websiteUrl : config.app.url);
  };

  useEffect(() => {
    const onKey = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "i" && user?.isSuperAdmin) {
        toggleImpersonatePanel();
        event.preventDefault();
        return;
      }
    };

    window.addEventListener("keydown", onKey);

    return () => {
      window.removeEventListener("keydown", onKey);
    };
  }, [user?.isSuperAdmin, showImpersonatePanel]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onClickMobileMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const isMenuOpen = Boolean(anchorEl);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const canAccessOneOfTheDashboards =
    permissions.canAccessSalaryBandsPeopleDashboard || permissions.canAccessMarketDataPeopleDashboard;

  const topMenu = useMemo(
    () =>
      compact([
        !user && {
          title: t("components.layout.navbar.try-the-app"),
          to: { pathname: "/try/market-data" } as const,
          icon: (className?: string) => <ImportantDevices fontSize="medium" className={className} />,
        },
        !user && {
          title: t("components.layout.navbar.salary-converter"),
          to: {
            pathname: "/global-salary-converter/[from]/[to]/[amount]",
            query: { from: "berlin", to: "paris", amount: "50000" },
          } as LinkProps["to"],
          icon: (className?: string) => <TravelExploreOutlined fontSize="medium" className={className} />,
        },
        permissions.canAccessMarketData && {
          title: t("components.layout.navbar.market-data"),
          to: { pathname: "/market-data" } as const,
          icon: (className?: string) => <Balance fontSize="medium" className={className} />,
        },
        permissions.canAccessCompanyDashboard && {
          title: isVentureCapital ? t("components.layout.navbar.portfolio") : t("components.layout.navbar.dashboard"),
          to: { pathname: isVentureCapital ? "/vc-dashboard" : "/company-dashboard" } as const,
          icon: (className?: string) => {
            if (isVentureCapital) return <DisplaySettings fontSize="medium" className={className} />;
            return <Dashboard fontSize="medium" className={className} />;
          },
        },
        permissions.canAccessGenderPayGapReport && {
          title: "Gender Pay Gap",
          to: { pathname: "/analytics/gender-pay-gap-report" } as const,
          icon: (className?: string) => (
            <div className="relative">
              <Insights fontSize="medium" className={className} />
              <NavbarChip>{t("common.new")}</NavbarChip>
            </div>
          ),
        },
        //needs to be changed :-(
        canAccessOneOfTheDashboards && {
          title: t("components.layout.navbar.people"),
          to: { pathname: "/people/salary-bands" } as const,
          icon: (className?: string) => <Groups fontSize="medium" className={className} />,
        },
        user &&
          permissions.canAccessSalaryBands && {
            title: t("components.layout.navbar.salary-bands"),
            to: { pathname: "/salary-bands" } as const,
            icon: (className?: string) => (
              <WaterfallChart fontSize="medium" className={classNames("rotate-90", className)} />
            ),
          },
        permissions.canAccessRawData &&
          CAN_ACCESS_COMPENSATION_PLANNING && {
            title: t("components.layout.navbar.budget"),
            beta: true,
            to: { pathname: "/compensation-planning" } as const,
            icon: (className?: string) => (
              <div className="relative">
                <Calculate fontSize="medium" className={className} />
              </div>
            ),
          },
        user && {
          title: t("components.layout.navbar.review"),
          to: { pathname: "/compensation-review" } as const,
          icon: (className?: string) => (
            <div className="relative">
              <CompensationReview fontSize="medium" className={className} />
            </div>
          ),
        },
      ]),
    [user, isDesktop]
  );

  return (
    <nav
      className="fixed bottom-0 top-0 z-30 flex h-[64px] w-full shrink-0 transform flex-row items-center whitespace-nowrap bg-primary-500 font-title font-medium transition-[width] md:h-screen md:w-[84px] md:flex-col"
      role="nav"
    >
      <div
        className={classNames("flex h-full w-full flex-row items-center md:flex-col md:pt-8", {
          "md:pb-4": !impersonatingCompany,
        })}
      >
        <Link
          className="ml-6 flex items-center md:ml-0 md:pb-6"
          to={user ? { pathname: "/home" } : buildFiguresUrl("/trial?utm_source=figures-app-nav")}
          title={t("components.layout.navbar.figures-homepage")}
        >
          <Image alt="Figures Logo" height={32} src="/images/favicon.svg" width={32} className="shadow" />
        </Link>

        {!isLoadingUser && (
          <div className="hidden h-full w-full flex-col justify-between md:flex">
            <div className="flex flex-col items-center">
              {topMenu.map(({ to, icon, title }, index) => (
                <ActiveLink
                  key={index}
                  passHref
                  activeClassName="border-l-4 border-white bg-primary-700"
                  iconActiveClassName="text-white"
                  className="flex w-full justify-center whitespace-normal py-3 text-center hover:bg-primary-400"
                  to={to}
                  inactiveClassName="hover:text-white text-primary-100"
                  icon={icon()}
                  title={title}
                  titleActiveClassName="text-white"
                />
              ))}
            </div>

            <div className="flex flex-col items-center">
              {user && (
                <div className="flex w-full flex-col">
                  <button
                    className={classNames(
                      "flex w-full flex-col items-center justify-center space-y-1 py-3 hover:!bg-primary-400 hover:text-white focus:outline-none",
                      {
                        "text-white hover:text-white": showPalette,
                        "text-primary-100 hover:text-white": !showPalette,
                        "bg-primary-400": showPalette,
                      }
                    )}
                    onClick={() => togglePalette()}
                  >
                    <Search fontSize="medium" />
                  </button>

                  {(permissions.canAccessAccount || permissions.canSetupSSO) && <AccountNavigationDropDown />}

                  {user && (
                    <ProfileDropDown
                      user={user}
                      onSetShowImpersonatePanel={() => toggleImpersonatePanel()}
                      onSignOut={() => signOutAndRedirect()}
                    />
                  )}
                </div>
              )}

              {!user && (
                <>
                  <ActiveLink
                    activeClassName="border-l-4 border-white bg-primary-700"
                    iconActiveClassName="text-white"
                    className="flex w-full justify-center py-3 hover:bg-primary-400"
                    to="/sign-in"
                    inactiveClassName="hover:text-white text-primary-100"
                    icon={<Icon name="user-arrow" size="2xl" />}
                    title={t("components.layout.navbar.sign-in")}
                    titleActiveClassName="text-white"
                  />

                  <ActiveLink
                    activeClassName="border-l-4 border-white bg-primary-700"
                    iconActiveClassName="text-white"
                    className="flex w-full justify-center py-3 hover:bg-primary-400"
                    to={buildFiguresUrl("/demo?slug=en_app_preview")}
                    inactiveClassName="hover:text-white text-primary-100"
                    icon={<RocketLaunch fontSize="medium" />}
                    title={t("components.layout.navbar.get-started")}
                    titleActiveClassName="text-white"
                  />
                </>
              )}
            </div>
          </div>
        )}

        <IconButton color="white" onClick={onClickMobileMenu} className="ml-auto mr-6 md:hidden">
          <MenuIcon />
        </IconButton>

        <MuiMenu anchorEl={anchorEl} open={isMenuOpen} onClose={() => setAnchorEl(null)}>
          {topMenu.map(({ to, icon, title }, index) => (
            <MenuItem key={index}>
              <Link noLinkStyle to={to} className="flex">
                {icon("mr-2 my-auto text-primary-500")}
                <Typography>{title}</Typography>
              </Link>
            </MenuItem>
          ))}

          {user && (
            <MenuItem>
              <Link noLinkStyle to="/account" className="flex">
                <Settings className="mr-2 text-primary-500" fontSize="medium" />
                <Typography>{t("components.layout.navbar.account")}</Typography>
              </Link>
            </MenuItem>
          )}

          {user && (
            <MenuItem onClick={() => signOutAndRedirect()}>
              <div className="flex">
                <Icon className="my-auto mr-2 text-primary-500" name="sign-out" size="lg" />
                {/* eslint-disable-next-line figures/forbid-props */}
                <Typography className="my-auto">Sign-out</Typography>
              </div>
            </MenuItem>
          )}

          {!user && (
            <MenuItem>
              <Link noLinkStyle to="/sign-in" className="flex">
                <Icon className="my-auto mr-2 text-primary-500" name="cog" size="lg" />
                <Typography>{t("components.layout.navbar.sign-in")}</Typography>
              </Link>
            </MenuItem>
          )}
        </MuiMenu>
      </div>

      {user?.isSuperAdmin && (
        <ImpersonatePanel isOpen={showImpersonatePanel} onClose={() => toggleImpersonatePanel()} />
      )}
    </nav>
  );
};

const NavbarChip: React.FC<{ children: string }> = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Typography
      /* eslint-disable-next-line figures/forbid-props */
      className={classNames("absolute rounded bg-secondary-500/80 px-0.5 text-2xs", {
        "-right-4 -top-2": isDesktop,
        "-right-24 top-0": !isDesktop,
      })}
      variant="caption"
      color="white"
    >
      {children}
    </Typography>
  );
};

type ProfileDropDownProps = {
  user: User;
  onSetShowImpersonatePanel: () => void;
  onSignOut: () => void;
};

const ProfileDropDown: React.FC<ProfileDropDownProps> = ({ user, onSetShowImpersonatePanel, onSignOut }) => {
  const { t } = useI18n();
  const { permissions } = usePermissions();
  const { impersonatingCompany, impersonatingUser } = useSession();

  const [isFrench, toggleIsFrench] = useToggle(user.locale === UserLocale.FR);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isRequestTemporarySuperAdminRightsPanelOpen, setIsRequestTemporarySuperAdminRightsPanelOpen] = useState(false);
  const [isRequestExceptionalImpersonationPanelOpen, setIsRequestExceptionalImpersonationPanelOpen] = useState(false);

  const onClose = () => setMenuAnchorEl(null);
  const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const isMenuOpen = !!menuAnchorEl;

  const updateUserLocale = useUpdateUserLocaleQuery({
    onSuccess: () => {
      window.location.reload();
    },
  });

  const stopCompanyImpersonationMutation = useStopCompanyImpersonationMutation();

  return (
    <>
      <button
        onClick={onButtonClick}
        className={classNames("mb-2 flex w-full flex-col justify-center space-y-1 py-3", {
          "bg-primary-400": isMenuOpen,
        })}
      >
        <Image
          alt="User profile picture"
          className="rounded-full"
          containerClassName="rounded-full border-2 mx-auto  border-secondary-600 hover:border-secondary-400"
          height={36}
          src={user.profilePictureUrl}
          width={36}
        />
      </button>

      <StyledPopper
        className="z-99 !p-0"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        placement="right-start"
        modifiers={[{ name: "offset", options: { offset: [0, -4] } }]}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Stack>
            <MenuList className="!p-1  focus:outline-none" tabIndex={-1}>
              {!impersonatingCompany && !impersonatingUser && (
                <>
                  <DevUserRoleSelector />
                  {config.app.isLocal && <Divider />}
                  <ToggleSuperAdmin />

                  {!user.isSuperAdmin &&
                    permissions.canRequestSuperAdminRights &&
                    user.companyId === getFiguresCompanyId() && (
                      <>
                        <RequestTemporarySuperAdminRightsPanel
                          isOpen={isRequestTemporarySuperAdminRightsPanelOpen}
                          onClose={() => setIsRequestTemporarySuperAdminRightsPanelOpen(false)}
                        />

                        <NavigationDropDownItem
                          onClick={() => setIsRequestTemporarySuperAdminRightsPanelOpen(true)}
                          title="Request Temporary Super Admin Rights"
                          icon={<SafetyCheck fontSize="small" />}
                        />
                      </>
                    )}

                  {config.app.isLocal && <Divider />}
                </>
              )}

              {user.isSuperAdmin && (
                <>
                  <NavigationDropDownItem
                    to="/admin"
                    title="Backoffice"
                    icon={<AssuredWorkloadOutlined fontSize="small" />}
                  />

                  <NavigationDropDownItem
                    onClick={() => onSetShowImpersonatePanel()}
                    title="Impersonate with granted access"
                    icon={<ScreenShareOutlined fontSize="small" />}
                  />

                  <RequestExceptionalImpersonationPanel
                    isOpen={isRequestExceptionalImpersonationPanelOpen}
                    onClose={() => setIsRequestExceptionalImpersonationPanelOpen(false)}
                  />

                  <NavigationDropDownItem
                    onClick={() => setIsRequestExceptionalImpersonationPanelOpen(true)}
                    title="Request Exceptional Impersonation"
                    icon={<Policy fontSize="small" />}
                  />

                  {impersonatingCompany && (
                    <NavigationDropDownItem
                      onClick={() => stopCompanyImpersonationMutation.mutate({})}
                      title="Stop impersonation"
                      icon={<StopScreenShareOutlined fontSize="small" />}
                    />
                  )}

                  <Divider />
                </>
              )}

              <NavigationDropDownItem
                onClick={onSignOut}
                title={t("components.layout.navbar.sign-out")}
                icon={<ExitToAppOutlined fontSize="small" />}
              />

              <MenuItem sx={{ paddingLeft: "8px" }} className="hover:bg-primary-100">
                <Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={isFrench}
                        sx={{
                          width: "36px",
                          marginRight: "3px !important",
                        }}
                        onChange={async () => {
                          toggleIsFrench();
                          await updateUserLocale.mutateAsync({});
                        }}
                      />
                    }
                    label={
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography>{t("components.layout.navbar.french")}</Typography>
                      </Stack>
                    }
                  />
                </Typography>
              </MenuItem>

              <Link
                sx={{ paddingLeft: "8px", margin: "4px" }}
                className="flex py-[6px] hover:bg-primary-100"
                to={
                  isFrench
                    ? "https://figures.hr/other/politique-de-confidentialite-de-lapplication"
                    : "https://figures.hr/other/application-privacy-policy"
                }
              >
                <Typography>{t("components.layout.navbar.privacy-policy")}</Typography>
              </Link>
            </MenuList>
          </Stack>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

const AccountNavigationDropDown: React.FC = () => {
  const { t } = useI18n();
  const router = useRouter();
  const { permissions, role } = usePermissions();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const onClose = () => {
    setMenuAnchorEl(null);
  };
  const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const isMenuOpen = !!menuAnchorEl;

  return (
    <>
      <button
        onClick={onButtonClick}
        className={classNames(
          "flex w-full flex-col justify-center space-y-1 py-3 hover:!bg-primary-400 hover:text-white focus:outline-none",
          {
            "text-white hover:text-white": router.route.startsWith("account"),
            "text-primary-100 hover:text-white": !router.route.startsWith("account"),
            "bg-primary-400": isMenuOpen,
          }
        )}
      >
        <Settings fontSize="medium" className="mx-auto" />
      </button>

      <StyledPopper
        className="z-99 !p-0"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        placement="right-start"
        modifiers={[{ name: "offset", options: { offset: [0, -4] } }]}
      >
        <ClickAwayListener onClickAway={onClose}>
          <MenuList className="!p-1  focus:outline-none" tabIndex={-1}>
            {(role.isHr || permissions.canAccessIntegrations) && (
              <NavigationDropDownItem
                to="/account/imported-employees"
                title={t("components.layout.navbar.imported-employees")}
                icon={<GroupAddOutlined fontSize="small" />}
              />
            )}

            <NavigationDropDownItem
              to="/account/populations"
              title={t("components.layout.navbar.populations")}
              icon={<SettingsAccessibility fontSize="small" />}
            />

            {permissions.canAccessRawData && (
              <NavigationDropDownItem
                to="/account/mapping"
                title={t("components.layout.navbar.mapping")}
                icon={<MultipleStopOutlined fontSize="small" />}
              />
            )}

            {permissions.canAccessIntegrations && (
              <NavigationDropDownItem
                to="/account/integrations"
                title={t("components.layout.navbar.integrations")}
                icon={<CloudSyncOutlined fontSize="small" />}
              />
            )}

            {permissions.canAccessAccount && (
              <NavigationDropDownItem
                to="/account/users"
                title={t("components.layout.navbar.users")}
                icon={<PeopleAltOutlined fontSize="small" />}
              />
            )}

            {permissions.canAccessRawData && (
              <NavigationDropDownItem
                to="/account/surveys"
                title={t("components.layout.navbar.surveys")}
                icon={<PollOutlined fontSize="small" />}
              />
            )}

            {permissions.canAccessSettings && (
              <NavigationDropDownItem
                to="/account/compensation-policy"
                title={t("components.layout.navbar.compensation-policy")}
                icon={<PriceChangeOutlined fontSize="small" />}
              />
            )}

            {permissions.canAccessPermissionsCenter && (
              <NavigationDropDownItem
                to="/account/permissions-center"
                title={t("components.layout.navbar.permissions-center")}
                icon={<LocalPoliceOutlined fontSize="small" />}
              />
            )}

            {permissions.canAccessFileManager && (
              <NavigationDropDownItem
                to="/account/file-manager"
                title={t("components.layout.navbar.file-manager")}
                icon={<FileUpload fontSize="small" />}
              />
            )}

            {permissions.canAccessRawData && (
              <NavigationDropDownItem
                to="/account/data-validation"
                title="Data Validation"
                icon={<Notifications fontSize="small" />}
              />
            )}

            {(permissions.canAccessSettings || permissions.canSetupSSO) && (
              <NavigationDropDownItem
                to="/account/settings"
                title={t("components.layout.navbar.settings")}
                icon={<SettingsOutlined fontSize="small" />}
              />
            )}
          </MenuList>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

type NavigationDropDownItemProps = {
  title: string;
  icon: JSX.Element;
  to?: NextLinkComposedProps["to"];
  onClick?: React.MouseEventHandler<HTMLLIElement>;
};

const NavigationDropDownItem: React.FC<NavigationDropDownItemProps> = ({ title, icon, to, onClick }) => {
  const router = useRouter();

  const content = (
    <Stack direction="row" spacing={2} alignItems="end">
      <div className="text-gray-500">{icon}</div>

      <Typography>{title}</Typography>
    </Stack>
  );

  if (to) {
    const isCurrentRoute = router.pathname === to;

    return (
      <MenuItem
        component={NextLinkComposed}
        to={to}
        sx={{ paddingLeft: "8px" }}
        className={classNames({
          "hover:bg-primary-200": true,
          "bg-primary-100": isCurrentRoute,
        })}
      >
        {content}
      </MenuItem>
    );
  }

  return (
    <MenuItem sx={{ paddingLeft: "8px" }} className="hover:bg-primary-100" onClick={onClick}>
      {content}
    </MenuItem>
  );
};
